import { withEmotionCache } from '@emotion/react';
import { json } from '@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import confirmModalCSS from 'react-confirm-alert/src/react-confirm-alert.css';

import { Button } from '~/components/ui/button';

import icon from '../public/favicon.svg';
import { ToastComponent } from './components';
import { LampContainer } from './components/ui/lamp';
import { useToast } from './hooks';
import { commitSession, getCurrentSession } from './services/session.server';
import animatedInputCSS from './styles/loginHero.css';
import sidebarStyes from './styles/sidebar.css';
import stepCounterStyles from './styles/stepCounter.css';
import tailwindCSS from './styles/tailwind.css';
import { blurInMoveDown, blurInMoveUp } from './utils';

import type { ToastMessage } from './hooks';
import type { LinksFunction, LoaderFunction } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: tailwindCSS },
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'
},
{
  rel: 'stylesheet',
  href: sidebarStyes
},
{
  rel: 'stylesheet',
  href: stepCounterStyles
},
{
  rel: 'stylesheet',
  href: confirmModalCSS
},
{
  rel: 'stylesheet',
  href: animatedInputCSS
},
{ rel: 'icon', type: 'image/svg+xml', href: icon }];


export const loader: LoaderFunction = async ({ request }) => {
  const GLOBALS = JSON.stringify({
    RAFFLE_ENV: process.env.RAFFLE_ENV
  });

  const session = await getCurrentSession(request);

  const toastMessage = session.get('toastMessage') as ToastMessage;

  if (!toastMessage) {
    return json({ toastMessage: null, GLOBALS });
  }

  if (!toastMessage.type) {
    throw new Error('Message should have a type');
  }

  return json(
    { toastMessage, GLOBALS },
    { headers: { 'Set-Cookie': await commitSession(session) } }
  );
};

export const meta: MetaFunction = ({ error }) => [
{
  title: error ? 'Uh-oh!' : 'Raffle.ai'
}];


type DocumentProps = {
  GLOBALS?: string;
  children: React.ReactNode;
};
const Document = withEmotionCache(
  ({ children, GLOBALS }: DocumentProps, emotionCache) => {
    useEffect(() => {
      emotionCache.sheet.container = document.head;

      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        (emotionCache.sheet as any)._insertTag(tag);
      });
    }, [emotionCache.sheet]);

    return (
      <html lang="en" className="no-scrollbar">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Meta />
          <Links />
        </head>
        <body className="bg-primary-600">
          {children}
          <ScrollRestoration />
          <script
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: `window.GLOBALS=${GLOBALS};`
            }} />

          <Scripts />
          <LiveReload />
        </body>
      </html>);

  }
);

function App() {
  const { toastMessage, GLOBALS } = useLoaderData<typeof loader>();

  useToast(toastMessage);

  return (
    <Document GLOBALS={GLOBALS}>
      <ToastComponent />
      <Outlet />
    </Document>);

}

export default withSentry(App);

export function ErrorBoundary({ error }: {error: Error;}) {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }

  return (
    <Document>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex h-full w-full items-center justify-center bg-primary-600">

        <LampContainer className="absolute bottom-0 w-full">
          <div className="absolute flex transform flex-col gap-10">
            <div className="flex flex-col gap-1">
              <motion.p
                {...blurInMoveDown}
                className="text-left text-[15px] text-neutral-400 shadow-md">

                We're very sorry
              </motion.p>
              <motion.p
                {...blurInMoveDown}
                transition={{ delay: 0.8, duration: 1, ease: 'easeOut' }}
                className="text-[40px] font-semibold tracking-tight !text-neutral-50 shadow-md">

                Something went wrong 😕
              </motion.p>
              <motion.p
                {...blurInMoveDown}
                transition={{ delay: 0.9, duration: 1.2, ease: 'easeOut' }}
                className="text-xl font-medium text-neutral-400 shadow-md">

                Take one of the actions below to proceed
              </motion.p>
            </div>

            <motion.div {...blurInMoveUp} className="flex items-center gap-4">
              <Button onClick={() => location.reload()} className="!rounded-md">
                Try again
              </Button>

              <Link to="/logout">
                <Button variant="secondary" className="!rounded-md">
                  Log out
                </Button>
              </Link>
            </motion.div>
          </div>
        </LampContainer>
      </motion.div>
    </Document>);

}