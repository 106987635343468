'use client';
import { motion } from 'framer-motion';
import React from 'react';

import { cn } from '~/utils';

export function LampDemo() {
  return (
    <LampContainer>
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut',
        }}
        className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 bg-clip-text py-4 text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
      >
        Build lamps <br /> the right way
      </motion.h1>
    </LampContainer>
  );
}

export const LampContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      'relative z-0 flex min-h-screen w-full flex-col items-center justify-center overflow-hidden rounded-md bg-primary-600',
      className,
    )}
  >
    <div className="relative isolate z-0 flex w-full flex-1 scale-y-125 items-center justify-center">
      <motion.div
        initial={{ opacity: 0.5, width: '5rem' }}
        whileInView={{ opacity: 1, width: '30rem' }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut',
        }}
        style={{
          backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
        }}
        className="bg-gradient-conic absolute inset-auto right-1/2 h-56 w-[30rem] overflow-visible from-primary-400 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]"
      >
        <div className="absolute bottom-0 left-0 z-20 h-40 w-[100%] bg-primary-600 [mask-image:linear-gradient(to_top,white,transparent)]" />
        <div className="absolute bottom-0 left-0 z-20 h-[100%] w-40 bg-primary-600 [mask-image:linear-gradient(to_right,white,transparent)]" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0.5, width: '5rem' }}
        whileInView={{ opacity: 1, width: '30rem' }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut',
        }}
        style={{
          backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
        }}
        className="bg-gradient-conic absolute inset-auto left-1/2 h-56 w-[30rem] from-transparent via-transparent to-primary-400 text-white [--conic-position:from_290deg_at_center_top]"
      >
        <div className="absolute bottom-0 right-0 z-20 h-[100%] w-40 bg-primary-600 [mask-image:linear-gradient(to_left,white,transparent)]" />
        <div className="absolute bottom-0 right-0 z-20 h-40 w-[100%] bg-primary-600 [mask-image:linear-gradient(to_top,white,transparent)]" />
      </motion.div>
      <div className="absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-primary-600 blur-2xl" />
      <div className="absolute top-1/2 z-50 h-48 w-full bg-transparent opacity-10 backdrop-blur-md" />
      <div className="absolute inset-auto z-50 h-36 w-[28rem] -translate-y-1/2 rounded-full bg-primary-400 opacity-50 blur-3xl" />
      <motion.div
        initial={{ width: '8rem' }}
        whileInView={{ width: '16rem' }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut',
        }}
        className="absolute inset-auto z-30 h-36 w-64 -translate-y-[6rem] rounded-full bg-primary-400 blur-2xl"
      />
      <motion.div
        initial={{ width: '5rem' }}
        whileInView={{ width: '30rem' }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut',
        }}
        className="absolute inset-auto z-50 h-0.5 w-[30rem] -translate-y-[7rem] bg-primary-350"
      />

      <div className="absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-primary-600" />
    </div>

    <div className="absolute z-50 flex h-full w-screen flex-col items-center justify-center px-5">
      {children}
    </div>
  </div>
);
